import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Testimonial.scss'
import LightSpeed from 'react-reveal/LightSpeed';

const Testimonial = () =>{

  const data = [
    {
      name: 'Paweł',
      position: 'Owner',
      des: 'The website you created grabs attention and generates profits.',
      img: 'https://cdn.pixabay.com/photo/2020/01/26/18/52/porsche-4795517_1280.jpg'
    },

    {
      name: 'WysPa',
      position: 'Owner',
      des: 'The website is simply perfect, thank you for that',
      img: 'https://cdn.pixabay.com/photo/2013/04/06/21/03/woodworker-101251_1280.jpg'
    },

    {
      name: 'Radek',
      position: 'Director',
      des: 'I liked his work',
      img: 'http://www.konsorcjum-grajewo.pl/images/logo2.png'
    },

    {
      name: 'Anita',
      position: 'CEO',
      des: 'Looking forward to further collaboration',
      img: 'https://cdn.pixabay.com/photo/2016/02/01/16/10/eye-1173863_1280.jpg'
    },

  ]

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    initialSlide: 0,
    autoplay: true,
    rows: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return(
    <div className='container testimonial-section' id="testimonial">
      <div className='section-title'>
        <h5>Testimonial</h5>
        <span className='line'></span>
      </div>
      <LightSpeed left>
        <div className="testimonial-slider">
          <Slider {...settings}>
            {data.map((item, index)=>(
              <div className="content-slider-main" key={index}>
                <div className="content-slider" key={index}>
                  <img src={item.img} alt="img" className="center-image"/>
                  <p>{item.des}</p>
                  <p>{item.name}</p>
                  <p>{item.position}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </LightSpeed>
    </div>
  )
}

export default Testimonial;
import React from 'react'
import { useState } from 'react'
import Roll from 'react-reveal/Roll';
import './TechStack.scss'

const TechStack = () =>{

  const [ showMoreTechStack, setShowMoreTechStack ] = useState(9)

  const loadMore = () =>{
    setShowMoreTechStack((prev)=> prev + 3);
  }

  const data = [
    { name: 'Full Stack Development' },
    { name: 'JavaScript' },
    { name: 'React' },
    { name: 'Redux' },
    { name: 'Node.js' },
    { name: 'Express.js' },
    { name: 'Python' },
    { name: 'HTML' },
    { name: 'SCSS/CSS' },
    { name: 'Bootstrap' },
    { name: 'Responsive Web Design' },
    { name: 'RESTful APIs' },
    { name: 'Web Animations' },
    { name: 'Multi-language Support' },
    { name: 'Nodemailer' },
    { name: 'CORS & Security' },
    { name: 'Environment Variables' },
    { name: 'MongoDB' },
    { name: 'MySQL' },
    { name: 'Git' },
    { name: 'NPM' },
    { name: 'Docker' },
    { name: 'Webpack' }, 
  ];

  const colors = [
    "#E04221",
    "#453AA6",
    "#56A63A",
    "#853AA6",
    "#A63A92",
    "#CB2647",
    "#F31B05",
    "#F3EF05",
    "#05EFF3",
    "#3F05F3",
    "#8E05F3",
    "#0D6546",
    "#70950D",
    "#95450D",
    "#7356F4",
    "#FF5733", 
    "#CDDC39", 
    "#9C27B0", 
    "#00BCD4", 
    "#FFEB3B", 
    "#E91E63", 
    "#3F51B5", 
    "#4CAF50", 
  ]

  return(
    <div className='container tech-stack-section' id='tech'>
      <div className='section-title'>
        <h5>Tech Stack</h5>
        <span className='line'></span>
      </div>
      <Roll right cascade>
        <div className='row'>
          {data.slice(0,showMoreTechStack).map((item,index)=>(
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12' key={index} >
              <div className='tech-content' style={{backgroundColor: colors[index]}}>
                <span className='tech-number' style={{backgroundColor: colors.reverse()[index]}}>
                  {index + 1}
                </span>
                <p>{item.name}</p>
              </div>
            </div>
          ))}
        </div>
      </Roll>
      {showMoreTechStack < data.length &&<div className='load-more-tech-stack' onClick={loadMore}>
        Load more
      </div>}
    </div>
  )
}

export default TechStack;
import './WorkExperience.scss'
import { GiDiamondHard } from "react-icons/gi";
import { PiStudentDuotone } from "react-icons/pi";
import { GiStairsGoal } from "react-icons/gi";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const WorkExperience = () =>{

  const data = [
    {
      companyname: 'ODev',
      position: 'Fullstack Web Developer',
      des: 'Creating websites and applications using the React JavaScript/TypeScript framework. Developing and maintaining backend services with NodeJS and ExpressJS, including managing MySQL databases and integrating with various APIs. Implementing best practices in security, including the use of JWT for authentication and authorization. Working with MUI and Bootstrap to create responsive and aesthetically pleasing interfaces. Using SASS/SCSS for better organization and readability of style sheets.',
      year: 'Jan 2023-Nov-2023',
      icon: <GiDiamondHard/>,
      techskills: [
        {
          techname: 'React'
        },
        {
          techname: 'JavaScript'
        },
        {
          techname: 'TypeScript'
        },
        {
          techname: 'NodeJS'
        },
        {
          techname: 'ExpressJS'
        },
        {
          techname: 'MySQL'
        },
        {
          techname: 'JWT'
        },
        {
          techname: 'Bootstrap'
        },
        {
          techname: 'MUI'
        },
        {
          techname: 'SASS/SCSS'
        }
      ]
    },
    {
      companyname: 'Freelance Web Development',
      position: 'Freelance Web Developer',
      des: 'Provided freelance web development services for clients, specializing in creating responsive and visually appealing websites. Collaborated closely with clients to understand their requirements and deliver customized solutions. Utilized HTML, CSS, JavaScript, and various web development frameworks to build websites that met clients\' needs and exceeded their expectations.',
      year: '2022-',
      icon: <GiDiamondHard/>,  
      techskills: [
        {
          techname: 'HTML'
        },
        {
          techname: 'CSS'
        },
        {
          techname: 'JavaScript'
        },
        {
          techname: 'Frameworks'
        },
        {
          techname: 'Client Relations'
        }
      ]
    },
    {
      companyname: 'Power IT Training',
      position: 'Owner',
      des: 'Owner of a training company specializing in acquiring and conducting trainings. Areas of training include Microsoft Office at all levels, graphic design, web development, training on proprietary software, network security, ICT, AutoCAD, and many others.',
      year: '2017-Present',
      icon: <GiDiamondHard/>,
      techskills: [
        {
          techname: 'Microsoft Office Training'
        },
        {
          techname: 'Software Training'
        },
        {
          techname: 'Web Development'
        },
        {
          techname: 'Team Management'
        },
        {
          techname: 'Network Security'
        },
        {
          techname: 'ICT'
        },
        {
          techname: 'AutoCAD'
        },
      ]
    },
  ]

  const colors = [
    "#FFBB28",
    "#FF8042",
    "#001CCE",
    "#4B088A",
    "#FF6347",
    "#FF1042",
    "Tomato"
  ]
  return(

    <div className='container workexperience-section' id='work'>
      <div className='section-title'>
        <h5>Work experience</h5>
        <span className='line'></span>
      </div>
      <div className='timeline-section'>
        <VerticalTimeline lineColor='#FF1042'> 
          {data.map((item, index)=>(
            <VerticalTimelineElement
              className="vertical-timeline-element--work" 
              contentStyle={{ background: colors[index+1], color: '#fff' }}
              contentArrowStyle={{ borderRight: '17px solid  gold' }}
              date={item.year}
              dateClassName='date-class'
              iconStyle={{ background: colors[index], color: '#fff' }}
              icon={item.icon}
              key={index}
            >
              <h3 className="vertical-timeline-element-title">{item.companyname}</h3>
              <h4 className="vertical-timeline-element-subtitle">{item.position}</h4>
              <div className="row">
                {item.techskills && item.techskills.map((item, index) =>(
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12" key={index}>
                    <div className="tech-skills">
                      <p>{item.techname}</p>
                    </div>
                  </div>
                ))}
              </div>
              <p>{item.des}</p>
            </VerticalTimelineElement>
          ))}
            <VerticalTimelineElement
              iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
              icon={<GiStairsGoal />}
            />
        </VerticalTimeline>
      </div>
    </div>
  )
}

export default WorkExperience;